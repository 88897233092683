@media screen and (max-width: $maxMobileSize) {
.banner-top {
    width: 100%;
    max-width: 540px;
    margin: auto;
    display: flex;
    flex-direction: column-reverse;
    .left {
      .pc {
        display: none;
        img {
          position: relative;
        }
        .car {
          top: 250px;
          left: 240px;
        }
        .phone {
          top: -100px;
          left: 240px;
        }
        .sticker {
          top: -30px;
          left: -100px;
        }
      }
      .mobile {
        position: relative;
      }
    }
    .right {
      text-align: right;
      height: 100%;
      .text-container {
        width: fit-content;
        height: fit-content;
        margin: 76px 0 47px auto;
        .small {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.05em;
          text-align: right;
          color: $grey04;
          margin: 24px 0;
        }
        .big {
          font-size: 22px;
          font-weight: 700;
          line-height: 30px;
          letter-spacing: -0.05em;
          text-align: right;
          margin: 10px 0;
        }
        .autocall-logo {
          width: 185px;
        }
      }
    }
  }
  .banner-menu {
    display: none;
  }
  .section-container {
    width: fit-content;
    margin: auto;
    .service1-text-container {
      margin: 187px 0 67px;
     .small {
      font-size: 20px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.025em;
      text-align: center;
      margin: 16px 0;
     } 
     .big {
        font-size: 22px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.025em;
        text-align: center;
        margin: 16px 0;
     }
    }
    .service1-section-tab {
      width: 100%;
      .tab-nav-container {
        display: grid;
        grid-template-columns: auto auto;
        .tab-nav-item {
          background-color: #fff;
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid black;
          height: 32px;
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: -0.025em;
          text-align: center;
          color: $grey05;
          cursor: pointer;
          &:hover {
            background-color: $grey05;
            color: #fff;
          }
          &.active {
            color: #fff;
            background-color: black;
            font-weight: bold;
          }
        }
      }
      .tab-contents {
        width: 100%;
        margin: 80px auto;
        .main-content {
          display: grid;
          grid-template-columns: 10% 80% 10%;
          align-content: center;
          margin-bottom: 45px;
          .arrow-btn {
            background: none;
            border: none;
            &.left {
              display: flex;
              align-items: center;
              flex-direction: row-reverse;
              img {
                width: 13px;
              }
            }
            &.right {
              display: flex;
              align-items: center;
              img {
                width: 13px;
              }
            }
          }
          .content {
            text-align: center;
            .img-container {
              img {
                width: 246px;
              }
            }
          }
          .text {
            width: 260px;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.025em;
            text-align: center;
          }
        }
        .small-nav {
          display: flex;
          gap: 8px;
          align-content: center;
          justify-content: center;
          .small-nav-item {
            width: 35px;
            height: 6px;
            background: $sub03;
            transition: .5s;
            &.active {
              background: $sub02;
            }
          }
        }
      }
    }
    .service2 {
      display: grid;
      .service2-left {
        margin: 111px 0 0;
        text-align: center;
        .red-light {
          width: 140px;
        }
        .text-container {
          .big {
            font-size: 28px;
            font-weight: 700;
            line-height: 34px;
            letter-spacing: -0.035em;
          }
          .sub1 {
            color: $grey03;
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.035em;
          }
          .sub2 {
            color: $grey06;
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            letter-spacing: -0.025em;
          }
        }
      }
      .service2-right {
        margin: 48px 0 0;
        text-align: center;
        .bubble {
          width: 304px;
          height: 89px;
        }
      }
    }
    .service3 {
      height: 800px;
      .pc-whitebox {
        text-align: center;
        .yellow-light {
          width: 140px;
          margin: 120px 0 16px;
        }
        .double-quete {
          color: $grey03;
          font-size: 18px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.025em;
        }
        .big {
          color: #fff;
          font-size: 28px;
          font-weight: 700;
          line-height: 34px;
          letter-spacing: -0.035em;
        }
        .sub {
          color: #fff;
          font-size: 14px;
          font-weight: 300;
          line-height: 20px;
          letter-spacing: -0.025em;
        }
      }
      .mobile-whitebox {
        text-align: center;
        width: 294px;
        height: 84px;
        background: #fff;
        border-radius: 14px;
        color: $grey01;
        padding: 17px;
        margin: auto;
        .bold {
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: -0.025em;
          margin: 12px 0;
        }
        .text {
          font-size: 12px;
          font-weight: 300;
          line-height: 18px;
          letter-spacing: -0.05em;
          width: 250px;
          margin: auto;
        }
      }
      .car-and-man {
        width: 300px;
        position: relative;
        top: 18px;
      }
    }
    .service4 {
      .left {
        text-align: center;
        .green-light {
          width: 140px;
          margin:120px 0 24px;
        }
        .text-container {
          text-align: center;
          .medium {
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.05em;
          }
          .big {
            font-size: 28px;
            font-weight: 700;
            line-height: 34px;
            letter-spacing: -0.035em;
            margin: 24px 0;
          }
          .small {
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            letter-spacing: -0.05em;
          }
        }
      }
      .right {
        text-align: right;
        margin: 67px auto;
        .touch-phone {
          width: 220px;
          height: 298px;
        }
      }
    }
    .service5 {
      text-align: center;
      width: 100%;
      padding: 160px 0 0;
      .text-container {
        .small {
          font-size: 20px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.025em;
          margin: 0;
        }
        .bold {
          font-size: 22px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: -0.025em;
          margin: 8px 0;
        }
      }
      .img-animation-container {
        img {
          position: relative;
          }
        .img-number1 {
          width: 180px;
          top: 150px;
          left: -80px;
          transition: 1s;
          transition-delay: .5s;
        }
        .img-number2 {
          width: 200px;
          top: 80px;
          left: -8px;
          transition: .6s;
          transition-delay: 1s;
        }
        .img-number3 {
          width: 150px;
          left: 10px;
          top: 70px;
          transition: .5s;
          transition-delay: 1.5s;
        }
        .img-shield {
          width: 240px;
          top: -80px;
          transition: 2s;
          transition-delay: 2s;
        }
      }
    }
    .work {
      height: 100%;
      display: grid;
      .left {
        padding: 173px 0 0;
        .text1 {
          font-size: 20px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.025em;
          margin: 0;
        }
        .text2 {
          font-size: 28px;
          font-weight: 700;
          line-height: 34px;
          letter-spacing: -0.05em;
          margin: 16px 0;
        }
        .text3 {
          font-size: 14px;
          font-weight: 300;
          line-height: 20px;
          letter-spacing: -0.05em;
          margin: 0;
        }
        .btn-container {
          margin: 32px 0 44px;
          button {
            width: 172px;
            height: 49px;
            border-radius: 51px;
            border: none;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: -0.05em;
            cursor: pointer;
          }
          .pdf {
            display: none;
          }
          .contact {
            background-color: $main01;
            &:hover {
              background-color: $main01;
            }
          }
        }
      }
      .right {
          height: 100%;
          margin: auto 0 0;
        .img-apt {
          width: 315px;
          height: 340px;
          position: relative;
          left: 30px;
        }
      }
    }
    .video {
      width: 100%;
      padding: 100px 0 0;
      .title {
        width: 105px;
        height: 20px;
        background-color: $main01;
        border-radius: 41px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.025em;
        color: #FFFFFF;
        padding: 12px 24px;
        margin: 0;
      }
      .sub-title {
        margin: 24px 0 43px;
        font-size: 22px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.05em;
      }
      .video-tab {
        .video-tab-nav {
          display: grid;
          grid-template-columns: auto auto auto;
          gap: 5px;
          .video-tab-nav-item {

            font-size: 18px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: -0.025em;
            color: $grey04;
            cursor: pointer;
            transition: 1s;
            .tab-index {
              text-align: center;
              width: 12px;
              height: 12px;
              font-size: 12px;
              font-weight: 600;
              line-height: 12px;
              letter-spacing: -0.025em;
              color: #fff;
              padding: 5.5px;
              border-radius: 23px;
              background-color: $grey05;
            }
            .nav-item-title {
              display: none;
            }
            .nav-item-subtitle {
              display: none;
            }
            &.active {
              color: black;
              .tab-index {
                background-color: black;
              }
              .nav-item-title {
                display: block;
                margin: 15px 0 0;
                font-weight: bold;
              }
              .nav-item-subtitle {
                display: block;
              }
            }
          }
        }
      }
      .video-container {
        width: 100%;
        height: 180px;
      }
    }
    .notice-news {
      .notice-news-title {
        width: fit-content;
        height: 20px;
        background-color: $main01;
        border-radius: 41px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.025em;
        color: #FFFFFF;
        padding: 12px 24px;
      }
      .sub-title{
        margin: 24px 0 43px;
        font-size: 22px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.05em;
      }
      .list-container {
        display: grid;
        gap: 16px;
        .list-contents {
          .header {
            display: flex;
            height: 90px;
            &.notice {
              border-bottom: 2px solid $grey01;
            }
          }
          .title {
            display: flex;
            width: fit-content;
            height: fit-content;
            margin: auto auto auto 0;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.025em;
            .img-box {
              width: fit-content;
              height: fit-content;
              margin: auto 12px auto 0;
              img {
                width: 20px;
              }
            }
          }
          .more-btn {
            height: fit-content;
            margin: auto 32px auto 0;
            color: $sub02;
            font-weight: bold;
            text-decoration-line: underline;
          }
          .list {
            &.notice {
              display: flex;
              flex-direction: column;
              height: 100%;
              margin: 0;
              padding: 0;
              .list-item {
                height: 64px;
                display: flex;
                flex-direction: column;
                border-top: 1px solid $grey05;
                &:hover {
                  opacity: 0.8;
                }
                .text {
                  margin: auto 0 auto 0;
                  color: black;
                }
              }
            }
            &.news {
              display: grid;
              grid-template-columns: auto auto;
              gap: 12px;
              padding: 0;
              .list-item {
                list-style-type: none;
                &:hover {
                  opacity: 0.8;
                }
                .text {
                  img {
                    width: 278px;
                    position: absolute;
                    z-index: 0;
                  }
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 14px;
                  letter-spacing: -0.03em;
                  text-align: left;
                  color: #fff;
                  .news-title {
                    width: 158px;
                    height: 103px;
                    background-size: contain;
                    .item-title {
                      width: 139px;
                      height: fit-content;
                      margin: 0 auto 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
 
  } 
}