@media screen and (min-width: $maxMobileSize) {
  .telecom-selector {
    width: 100%;
    display: flex;
    height: 50px;
    border-radius: 8px;

    .telecom-radio {
      display: none;
      &.skt {
        border-left: 1px solid $main01;
        &:checked ~ .telecom-label {
          &.skt {
            border-bottom: 1px solid $main01;
            border-radius: 8px 0px 0px 8px;
            background-color: $main01;
            color: #FFFFFF;
          }
        }
      }

      &.kt {
        border: none;

        &:checked ~ .telecom-label {
          &.kt {
            border-bottom: 1px solid $main01;
            border-radius: 0px;
            background-color: $main01;
            color: #fff;
          }
        }
      }

      &.lgu {
        &:checked ~ .telecom-label {
          &.lgu {
            border-radius: 0px 8px 8px 0px;
            background-color: $main01;
            color: #fff;
          }
        }
      }
    }

    .telecom-label {
      cursor: pointer;
      background-color: #ffffff;
      width: 33.33333%;
      height: 50px;
      font-size: 20px;
      font-weight: 700;
      line-height: 50px;
      letter-spacing: -0.05em;
      color: $main01;
      text-align: center;
      border-top: 1px solid $main01;
      border-bottom: 1px solid $main01;

      &.skt {
        border-radius: 8px 0px 0px 8px;
        border-left: 1px solid $main01;
      }

      &.lgu {
        border-radius: 0px 8px 8px 0px;
        border-right: 1px solid $main01;
      }

      &.kt {
        border-left: 1px solid $main01;
        border-right: 1px solid $main01;
      }

      .label-text {
        height: 50px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

@media screen and (max-width: $maxMobileSize) {
  .telecom-selector {
    width: 320px;
    display: flex;
    height: 40px;
    border-radius: 8px;
    //border: 1px solid $main01;
    grid-template-columns: auto auto auto;

    .telecom-radio {
      display: none;

      &.skt {
        border: none;

        &:checked ~ .telecom-label {
          &.skt {

            border-radius: 8px 0px 0px 8px;
            background-color: $main01;
            color: #FFFFFF;
          }
        }
      }

      &.kt {
        border: none;

        &:checked ~ .telecom-label {
          &.kt {

            border-radius: 0px;
            background-color: $main01;
            color: #fff;
          }
        }
      }

      &.lgu {
        &:checked ~ .telecom-label {
          &.lgu {

            border-radius: 0px 8px 8px 0px;
            background-color: $main01;
            color: #fff;
          }
        }
      }
    }

    .telecom-label {
      cursor: pointer;
      width: 100%;
      border: 1px solid $main01;
      height: 40px;
      text-align: center;
      color: $main01;

      &.lgu {;
        border-radius: 0px 8px 8px 0px;
      }

      &.skt {
        border-radius: 8px 0 0 8px;
      }

      .label-text {
        //padding-bottom: 1px;
        font-weight: 400;
        font-size: 14px;
        line-height: 42px;
        font-family: GmarketSansBold, serif;
        width: 32px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}