
@media screen and (max-width: $maxMobileSize) {
  .app {
    min-width: $minMobileSize;
    font-size: 70%;
    .base-layout {
        .page-layout {
        width: 100%;
        .page-header {
          width: 100%;
          height: 120px;
          margin: 0 auto 24px auto;
          background-color: white;
          .header-contents {
            height: 100%;
            width: 320px;
            margin:  0 auto;
            display: flex;
            .text-section {
              width: 320px;
              h1 {
                margin: 24px 0 0 0;
                font-weight: 500;
                font-size: 26px;
                line-height: 30px;
                font-family: GmarketSansBold,serif;
                letter-spacing: -0.05em;
                text-align: left;

              }
              p {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color:$grey02;
                letter-spacing: -0.05em;
                margin: 10px 0 24px 0;
              }
            }
            .img-section {
              display: none;
            }
          }
        }
        .page-contents {
          width: 320px;
          margin: 0px auto 0 auto;
          .support-menu-tab {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            margin-top: -40px;
            overflow-x:scroll;
            white-space:nowrap;
            -ms-overflow-style: none;
            margin-bottom:40px;
            scrollbar-width: none;
            &::-webkit-scrollbar {
              display: none;
            }
            .menu-item {
              border-radius: 50px;
              height: 40px;
              font-weight: 400;
              background-color: $sub08;
              border: none;
              letter-spacing: -0.025em;
              text-align: center;
              margin: 0px 6px 10px 0;
              line-height: 24px;
              color: $sub05;
              display: flex;
              >span{
              font-family: GmarketSansMedium, serif;
                font-weight: 400;
              padding: 8px 22px;
              font-size: 16px;
              }
              align-items: center;
              transition: 0.5s;
              &.active {
                background-color: $sub07;
                color: $main01;
                &:hover {
                  opacity: 0.7;
                }
              }
              &:hover {
                opacity: 0.7;
              }
            }
          }
          .faq-nav {
            margin-top: -10px;
            width: 100%;
            height: 33px;
            display: flex;
            align-items: center;
            .nav-item {
              padding: 10px 0 0 0;
              height: 33px;
              background: none;
              border: none;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: $grey03;
              margin: 12px 14px 0 0 ;
              padding-bottom: 12px;
              letter-spacing: -0.025em;
              cursor: pointer;
              text-align: center;
              &:hover {
                background: $sub01;
                color: #fff;
              };
              &.active {
                color: $grey01;
                border-bottom: 2px solid black;
                padding-bottom: 10px;
              }
            }
          }
 
        }
      }
      .loading-container {
        background: rgba(0, 0, 0, .7);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .inner-loading {
          img {
            margin: 0 auto;
            display: flex;
            width: 130px;
          }
        }
      }
    }
  }
}