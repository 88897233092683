@media screen and (min-width: $maxMobileSize) {
  .mo_floating_btn {
    display: none;
  }
  .floating-btn-container {
    .before_open {
      display: none;
    }

    background: none;
    position: fixed;
    bottom: 50px;
    right: 40px;
    z-index: 5;
    .floating-btn-top {
      .quick-menu-input {
        display: none;
      }

      .mobile {
        display: none;
      }

      label {
        display: none;
      }

      .pc {
        color: #7d898b;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.05em;
        border-radius: 100px;
        //padding: 0px 7.5px;
        background: #f8fbff;
        box-shadow: 0px 4px 8px rgba(96, 105, 122, 0.2);

        .btn {
          width: 80px;
          height: 110px;
          cursor: pointer;

          &.first {
            border-radius: 100px 100px 0 0;
          }

          &.second {}

          &.third {
            border-radius: 0 0 100px 100px;
          }

          .icon {
            padding-top: 20px;
            display: flex;

            .first {
              margin: 0 auto;
              width: 36px;
              height: 36px;
              background-image: url("../../assets/img/mainbanner/quick_apart_icon.png");
              background-repeat: no-repeat;
              background-position: 50% 50%;
              background-size: cover;
            }

            .second {
              margin: 0 auto;
              width: 36px;
              height: 36px;
              background-image: url("../../assets/img/mainbanner/quick_inquiry_icon.png");
              background-color: inherit;
              background-repeat: no-repeat;
              background-position: 50% 50%;
              background-size: cover;
            }

            .third {
              margin: 0 auto;
              width: 36px;
              height: 36px;
              background-image: url("../../assets/img/mainbanner/quick_qr_icon.svg");
              background-repeat: no-repeat;
              background-position: 50% 50%;
              background-size: cover;
            }
          }

          > a {
            display: block;
            //padding: 20px 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: $grey02;
          }
        }

        .btn:hover {
          background: linear-gradient(180deg, #0cbcd7 0%, #333172 100%);

          .icon {
            color: red;

            .first {
              background-image: url("../../assets/img/mainbanner/quick_apart_icon_hover.png");
            }

            .second {
              background-image: url("../../assets/img/mainbanner/quick_inquiry_icon_hover.png");
            }
          }

          &.second {
            color: #fff;
          }

          &.third {
            color: #fff;
          }

          &.first {
            > a {
              color: #fff;
            }
          }
        }

        .divider {
          width: 63.5px;
          height: 1px;
          background-color: #fff;
          margin: 14px auto;
        }
      }
    }

    .floating-btn-bot {
      z-index: 5;

      background-color: #f8fbff;
      border-radius: 50px;
      cursor: pointer;
      padding: 20px;
      text-align: center;
      margin-top: 13px;
      filter: drop-shadow(0px 4px 8px rgba(96, 105, 122, 0.2));

      .content {
        > img {
          width: 28px;
          height: 20px;
        }

        > span {
          display: inline-block;
          width: 28px;
          height: 20px;
          margin-top: 10px;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: cover;
          background-image: url("../../assets/img/mainbanner/main-side-up-icon.png");
        }

        .text {
          color: #fff;
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: -0.05em;
          text-align: center;
        }
      }
    }

    .floating-btn-bot:hover {
      background-color: $main01;

      .content {
        > span {
          background-image: url("../../assets/img/mainbanner/main-side-up-icon-hover.png");
        }
      }
    }
  }
}
