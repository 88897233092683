@import "./Web/wLayout.scss";
@import "./Mobile/mLayout.scss";
body {
  margin: 0;
  a {
    text-decoration: none;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  * {
    box-sizing: border-box;
  }
}
