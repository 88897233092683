@import "./telecom.scss";
@media screen and (min-width: $maxMobileSize) {
  .autocall-form {
    gap: 41px;
    margin: 30px auto 50px;
  }
  .form-item {
    display: flex;
    grid-template-columns: 30% auto;
    .form-info-container {
      width: 27.5%;
      display: block;
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.05em;
      text-align: left;
      height: fit-content;
      margin-top: 12px;
      .info-title {
        width: fit-content;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        &.active {
          &:after {
            color: $main01;
            font-size: 30px;
            content: " ·";
          }
        }
      }
      .info-subtitle_mo {
        display: none;
      }
      .info-subtitle {
        width: 200px;
        color: $grey02;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.05em;
        text-align: left;
        margin: 5px 0;
      }
      .max-length {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.025em;
        margin: 0;
        text-align: left;
        color: $grey02;
      }
    }
    .form-input-container {
      width: 50%;
      display: grid;
      gap: 16px;
      .under-text {
        color: #ff3f33;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.05em;
      }
      .terms-container {
        display: grid;
        margin-top: 12px;
        gap: 16px;
        .terms-checkbox {
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.05em;
          label {
            cursor: pointer;
            background: url("../assets/img/form/terms-btn.png") no-repeat left center;
            background-size: contain;
            padding: 0 10px 0 28px;
            input {
              display: none;
            }
          }
          button {
            border: none;
            background: none;
            cursor: pointer;
            height: 19.33px;
          }
          &.all {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.05em;
          }
          &.active {
            label {
              background-image: url("../assets/img/form/terms-btn-active.png");
            }
          }
        }
        .divider {
          width: 100%;
          height: 1px;
          background-color: $grey06;
        }
      }
      .postcode-container {
        z-index: 102;
        .close-btn {
          padding: 0 20px;
          text-align: right;
          width: 100%;
          height: 32px;
          font-size: 20px;
          font-weight: bold;
          background: none;
          border: none;
        }
        .daum-postcode-embed-container {
          height: fit-content;
          border: 1px solid black;
          text-align: right;
          margin: auto;
          width: 90%;
        }
      }
    }
    .valid-text-container {
      display: flex;
      width: 100%;
      color: $warning;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      gap: 3px;
      background-size: cover;

      .warning-icon {
        margin-top: -6px;
        margin-bottom: 24px;
        //padding-bottom: 2px;
        min-width: 18px;
        width: 18px;
        height: 18px;
        text-align: center;
        background-image: url("../assets/img/form/ico_warning.png");
        background-repeat: no-repeat;
        background-size: cover;
        &.green {
          background-image: url("../assets/img/form/ico_green.png");
        }
      }
      .warning-text {
        margin-top: -8px;
        height: 24px;
        &.green {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: $main01;
        }
      }
    }
    .mo-refund-button {
      display: none;
    }
  }
  .input-part {
    display: flex;
    gap: 7px;
    button {
      border-radius: 8px;
      width: 35%;
      height: 62px;
      border: none;
      background: $sub08;
      color: $sub05;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: -0.05em;
      text-align: center;
      &:hover {
        opacity: 0.8;
      }
    }
    .send-btn {
      &.active {
        background-color: $main01;
      }
    }
    .cert-btn {
      &.active {
        background-color: $main01;
      }
    }
  }
  .input-container {
    height: 60px;
    border-radius: 8px;
    width: 100%;
    display: flex;
    border: 1px solid $grey02;
    &:focus-within {
      border: 2px solid #10a8ca;
      box-shadow: 0px 4px 6px #daeef2;
    }
    &.warning {
      border: 2px solid $warning;
    }
    &.disabled {
      background-color: $grey02;
    }
    &.textarea {
      height: 280px;
      padding: 0;
    }
    &.complete {
      border: 2px solid #10a8ca;
      box-shadow: 0px 4px 6px #daeef2;
    }
    .input-content {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      align-content: center;
      .behind {
        z-index: -1;
      }
      > input {
        font-family: Pretendard-Regular, serif;
        border: none;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        background-color: white;
        letter-spacing: -0.05em;
        text-align: left;
        width: 100%;
        margin-left: 10px;
        &:focus {
          outline: 0;
        }
        &:disabled {
        }
      }
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      textarea {
        border: none;
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.05em;
        text-align: left;
        width: 100%;
        padding: 0;
        appearance: none;
        &:focus {
          outline: 0;
        }
        &:disabled {
          background: $grey05;
        }
      }
    }
    .countdown {
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      display: flex;
      align-self: center;
      color: $grey02;
      width: 70px;
      div {
        height: fit-content;
      }
    }
  }
}

@media screen and (max-width: $maxMobileSize) {
  .autocall-form {
    display: grid;
    margin: 0 auto;
    width: 320px;
  }
  .form-item {
    .form-info-container {
      display: flex;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.05em;
      text-align: left;
      margin: auto auto 8px 0;
      .info-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        &.active {
          &:after {
            color: $main01;
            font-size: 24px;
            content: " ·";
            line-height: 24px;
          }
        }
      }
      .info-astarick {
        color: $main01;
      }
      .info-subtitle {
        display: none;
        // display: inline-block;
        // width: 200px;
        // font-weight: 500;
        // font-size: 14px;
        // line-height: 18px;
        // color: $grey02;
      }
      .info-subtitle_mo {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        margin: 30px 0 0 -56px;
        color: #7d898b;
      }
      .max-length {
        margin: 30px 0 0 -67px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.025em;
        text-align: left;
        color: $grey02;
      }
    }
    .form-input-container {
      display: grid;
      .under-text {
        color: red;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        width: 320px;
        display: inline-block;
        margin: 20px auto 0 auto;
        letter-spacing: -0.05em;
        text-align: center;
      }
      .terms-container {
        display: grid;
        gap: 16px;
        margin: 10px 0 15px 0;
        .terms-checkbox {
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.05em;
          label {
            cursor: pointer;
            background: url("../assets/img/form/terms-btn.png") no-repeat left center;
            background-size: contain;
            height: 24px;
            line-height: 24px;
            padding: 0 6px 0 25px;
            transition: 0.3s;
            input {
              display: none;
            }
          }
          button {
            background: none;
            border: none;
            cursor: pointer;
          }
          &.all {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.05em;
          }
          &.active {
            label {
              background-image: url("../assets/img/form/terms-btn-active.png");
            }
          }
        }
        .divider {
          width: 100%;
          height: 1px;
          background-color: $grey06;
        }
      }
      .postcode-container {
        z-index: 102;
        .close-btn {
          text-align: right;
          width: 100%;
          height: 32px;
          font-size: 20px;
          font-weight: bold;
          background: none;
          border: none;
        }
        .daum-postcode-embed-container {
          height: fit-content;
          border: 1px solid black;
          text-align: right;
          margin: 0;
          width: 100%;
        }
      }
    }
    .valid-text-container {
      display: flex;
      color: $warning;
      font-size: 11.2px;
      font-weight: 400;
      margin-top: 3px;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      .warning-icon {
        min-width: 16px;
        width: 16px;
        height: 16px;
        text-align: center;
        background-image: url("../assets/img/form/ico_warning.png");
        background-repeat: no-repeat;
        background-size: cover;
        &.green {
          background-image: url("../assets/img/form/ico_green.png");
        }
      }
      .warning-text {
        margin-left: 5px;
        margin-bottom: 0px;
        display: inline-block;

        height: 20px;
        &.green {
          color: $main01;
        }
      }
    }
    .pc-refund-button {
      display: none
    }
  }
  .input-part {
    display: flex;
    height: 56px;
    border: none;
    border-radius: 10px;
    width: 320px;
    margin-bottom: 12px;
  }
  .input-container {
    box-sizing: unset;
    height: 56px;
    margin-bottom: 10px;
    display: flex;
    width: 320px;
    align-items: center;
    border: 1px solid #ced6d8;
    border-radius: 10px;
    &:focus-within {
      border: 2px solid $main01;
    }
    &.disabled {
      background-color: $grey02;
    }
    &.warning {
      border: 2px solid $warning;
    }
    &.complete {
      width: 320px;
      border: 2px solid #10a8ca;
      box-shadow: 0px 4px 6px #daeef2;
    }
    &.textarea {
      height: 280px;

      padding: 0;
    }
    .input-content {
      width: 95%;
      display: flex;
      border: 10px;
      margin: auto 15px;
      height: 55px;
      input {
        border-radius: 10px;
        border: none;
        padding: 0;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: -0.05em;
        text-align: left;
        width: 290px;
        &[type="number"] {
          -moz-appearance: textfield;
          appearance: none;
          &::-webkit-outer-spin-button,
          ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
          }
        }
        &:focus {
          outline: 0;
        }
        &:disabled {
          background-color: #fff;
        }
      }
      input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #a8b1b3;
        line-height: 24px;
      }
      textarea {

        border: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        letter-spacing: -0.05em;
        text-align: left;
        width: 280px;
        padding: 0;
        margin: -100px 0 0 5px;
        appearance: none;
        &:focus {
          outline: 0;
        }
        &:disabled {
          background-color: #fff;
        }
      }
    }
    .countdown {
      &.false{
        margin-left: -55px;
      }
      &.margin {
        margin-left: -145px;
      }
      color: $grey02;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  }
}
