
@media screen and (min-width: $maxMobileSize) {
  .home-popup-container {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, .7);
    z-index: 10;
    .home-popup-contents {
      height: fit-content;
      width: fit-content;
      margin: 30vh auto;
      .home-popup-img-container {
        width: fit-content;
        cursor: pointer;
        img {
          width: 360px;
        }
      }
      .home-popup-button-container {
        display: flex;
        button {
          background: none;
          border: none;
          width: 100%;
          height: 44px;
          color: $grey05;
          font-size: larger;
        }
        .left-btn {
        }
        .divider {
          // height: 10px;
          width: 2px;
          background: $grey05;
        }
        .right-btn {
        }
      }
    }
  }
}

@media screen and (max-width: $maxMobileSize) {
  .home-popup-container {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, .7);
    z-index: 10;
    .home-popup-contents {
      height: fit-content;
      width: fit-content;
      margin: 15vh auto;
      .home-popup-img-container {
        width: fit-content;
        cursor: pointer;
        img {
          width: 360px;
        }
      }
      .home-popup-button-container {
        display: flex;
        button {
          background: none;
          border: none;
          width: 100%;
          height: 44px;
          color: $grey05;
          font-size: larger;
        }
        .left-btn {
        }
        .divider {
          // height: 10px;
          width: 2px;
          background: $grey05;
        }
        .right-btn {
        }
      }
    }
  }
}