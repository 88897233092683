@media screen and (min-width: $maxMobileSize) {
  .autocall-btn {
    margin: 0 auto;
    border-radius: 8px;
    width: 100%;
    height: 74px;
    border: none;
    background-color: $grey03;
    letter-spacing: -0.05em;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    font-family: "GmarketSansBold", serif;
    font-size: 17px;
    line-height: 22px;
    color: #fff;

    &:hover {
      opacity: 0.8;
    }

    &.round {
      border: 2px solid $main01;
      background: none;
      color: $main01;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.025em;
      text-align: center;
      border-radius: 40px;
    }

    &.default {
      background-color: $main01;
      font-family: 'GmarketSansBold', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 22px;
      border-radius: 8px;
      box-shadow: 0 4px 14px #bad8de;
    }

    &.nomal2 {
      width: 170px;
      height: 50px;
      background: $main01;
      border-radius: 8px;
    }

    &.nomal {
      font-family: Pretendard-Regular, serif;
      height: 60px;
      width: 167px;
      padding: 0;
      border-radius: 8px;

      &.active {
        background-color: $sub07;
        color: $main01;
      }

      &.disabled {
        background-color: $sub08;
      }

      &.complete {
        background-color: $sub08;
      }

      &.refund {
        margin-left: 16px;
        background-color: $sub08;
      }

      &.refund-active {
        font-family: "GmarketSansBold", serif;
        background: #10A8CA;
        color: #FFF;
      }

      &.refund-disabled {
        font-family: GmarketSansBold, serif;
        background: var(--btn-main_dim_bg, #C2E7F2);
        color: var(--btn-main_dim-txt, #7FC6DA);
      }
    }

    &.cancel {
      font-size: 16px;
      background-color: $sub06;
      height: 50px;
      width: 170px;
      color: $sub05;
    }

    &.inlineButton {
      max-width: 360px;
      margin-bottom: 36px;
      background-color: $main01;
      font-family: 'GmarketSansBold', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 22px;
      border-radius: 8px;
      box-shadow: 0 4px 14px #bad8de;
    }
  }
  .join-submit {
    margin-top: 48px;
    width: 100%;
    margin-left: 55%;
  }
  .qna-submit, .refund-submit {
    margin-top: 36px;
    width: 100%;
    margin-left: 55%;
  }
  .refund-submit {
    > p {
      color: #F00;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      margin: 16px 0 0;
    }
  }
}

@media screen and (max-width: $maxMobileSize) {
  .autocall-btn {
    padding: 0;
    //word-break: break-all;
    z-index: 1;
    background: $sub08;
    border-radius: 10px;
    cursor: pointer;
    width: 70px;
    height: 40px;
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.05em;
    text-align: center;

    &.default {
      width: 320px;
      margin-top: 20px;
      margin-left: 0px;
      font-weight: 500;
      font-family: GmarketSansBold, serif;
      font-size: 18px;
      line-height: 18px;
      height: 60px;
      background-color: $main01;
    }

    &.round {
      border: 2px solid $main01;
      background: none;
      color: $main01;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.025em;
      text-align: center;
      border-radius: 40px;
    }

    &.cancel {
      width: 125px;
      height: 50px;
      color: $sub05;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      font-family: GmarketSansBold, serif;
      background-color: $sub06;
    }

    &.nomal {
      font-family: Pretendard-Regular, serif;
      display: inline-block;
      height: 40px;
      width: 70px;
      padding: 0;
      border-radius: 8px;

      &.active {
        background-color: $sub07;
        color: $main01;
        margin-left: -80px;
        margin-top: 8px;
      }

      &.disabled {
        background-color: $sub08;
        color: $sub05;
        margin-left: -80px;
        margin-top: 8px;
      }

      &.complete {
        background-color: $sub08;
        color: $sub05;
        margin-left: -80px;
        margin-top: 8px;
      }

      &.refund {
        background-color: $sub08;
        color: $sub05;
        margin-top: 8px;
        width: 100%;
      }
      &.refund-active {
        font-family: "GmarketSansBold", serif;
        background: #10A8CA;
        color: #FFF;
      }

      &.refund-disabled {
        font-family: GmarketSansBold, serif;
        background: var(--btn-main_dim_bg, #C2E7F2);
        color: var(--btn-main_dim-txt, #7FC6DA);
      }

    }

    &.nomal2 {
      width: 125px;
      height: 50px;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      margin: 0 auto;
      font-family: GmarketSansBold, serif;
      background-color: $main01;
    }

    &.inlineButton {
      width: 100%;;
      font-weight: 500;
      font-family: GmarketSansBold, serif;
      font-size: 18px;
      line-height: 18px;
      height: 60px;
      background-color: $main01;
    }

  }
}
