@import "~@csstools/normalize.css";
body {
  //-ms-overflow-style: none;
  //scrollbar-width: none;
  //&::-webkit-scrollbar {
  //  display: none;
  //}
}
@import "./font.scss";
@import "./variables.scss";
@import "./animation.scss";
@import "./layout.scss";

@import "./floatingBtn.scss";
@import "./modal.scss";
@import "./autocallBtn.scss";
@import "./popup.scss";
@import "./form.scss";
@import "./main.scss";
