@media screen and (min-width: $maxMobileSize) {
  .app {
    .base-layout {
      .page-layout {
        width: 100%;
        .page-header {
          width: 100%;
          height: auto;
          background-color: $white;
          .header-contents {
            &.qr,
            &.join {
              height: 224px;
            }
            &.usage,
            &.sticker,
            &.notice {
              height: 198px;
            }
            &.undefined {
              height: 158px;
            }
            max-width: $pageWidth;
            margin: auto;
            display: flex;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right center;
            .text-section {
              margin: 60px auto 60px 0;
              h1 {
                font-weight: 500;
                font-size: 38px;
                line-height: 38px;
                font-family: "GmarketSansBold", serif;
                letter-spacing: -0.05em;
                text-align: left;
                margin: 0 0 14px 0;
              }
              p {
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.025em;
                text-align: left;
                color: $grey02;
                margin-top: 16px;
              }
            }
          }
        }
        .page-contents {
          max-width: $pageWidth;
          margin: auto;
          .support-menu-tab {
            display: flex;
            padding-bottom: 46px;
            .menu-item {
              > span {
                font-family: GmarketSansMedium, serif;
                font-weight: 400;
                font-size: 18px;
                line-height: 50px;
                padding: 10px 20px;
              }
              height: 50px;
              padding: 0px 5px;
              color: $sub05;
              margin-right: 12px;
              background-color: $sub08;
              letter-spacing: -0.025em;
              text-align: center;
              border-radius: 50px;
              transition: 0.5s;
              &.active {
                background-color: $sub07;
                color: $main01;
                &:hover {
                  opacity: 0.7;
                }
              }
              &:hover {
                opacity: 0.7;
              }
            }
          }
          .faq-nav {
            width: 100%;
            height: 100%;
            text-align: left;
            .nav-item {
              font-weight: 400;
              font-size: 18px;
              line-height: 26px;
              width: auto;
              border: none;
              height: auto;
              background: none;
              letter-spacing: -0.025em;
              color: $grey03;
              cursor: pointer;
              text-align: center;
              margin-right: 18px;
              padding: 0;
              &:hover {
                //background: $sub01;
                //color: #fff;
              }
              &.active {
                padding: 0 0 10px 0;
                font-weight: 700;
                border-bottom: 2px solid $grey01;
                color: $grey01;
              }
            }
          }
        }
      }
      .loading-container {
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .inner-loading {
          img {
            width: 150px;
          }
        }
      }
    }
  }
}
