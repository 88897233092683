@media screen and (max-width: $maxMobileSize) {
  .floating-btn-container {
    z-index: 12;
    &.active{
      &::before {
        z-index: -1;
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
      }
    }
    .pc {
      display: none;
    }

    .quick-menu {
      display: none;
    }

    .before_open {
      box-sizing: unset;
      cursor: pointer;
      z-index: 999;
      width: 48px;
      margin: 0 0 0 auto;
      height: 48px;

      &.active {
        border: 6px solid #85e8ff;
        margin: 0 -6px -6px auto;
      }
      > img {
        display: inline-block;
        margin: 13.5px 9px;
        width: 30px;
        height: 21px;
      }

      border-radius: 100px;
      background: linear-gradient(180deg, #0abdd8 0%, #332e70 100%);
    }

    position: fixed;
    bottom: 80px;
    right: 20px;

    .floating-btn-top {
      display: block;

      .mobile {

        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        filter: drop-shadow(0px 6px 14px rgba(43, 54, 57, 0.3));
        background-image: url("../../assets/img/floatingBtn/item_container_bg.svg");
        width: 110px;
        height: 504px;

        text-align: center;

        .top {
          border-radius: 24px 24px 0 0;
        }

        .top:hover {
          border-radius: 24px 24px 0 0;
        }

        .bottom {
          border-radius: 0 0 24px 24px;
        }

        .bottom:hover {
          border-radius: 0 0 24px 24px;
        }

        > a {
          cursor: pointer;
          display: block;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: $grey02;
          width: 110px;
          height: 78px;

          > i {
            > img {
              width: 36px;
              height: 36px;
              margin: 10px 0 4px 0;
            }
          }
        }

        > a:hover {
          background: #dfe8ea;
        }
      }
    }
  }
  .floating-btn-bot {
    display: none;
  }
  .mo_floating_btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    height: 60px;
    z-index: 1;
    background: rgba(18, 18, 79, 0.9);
    backdrop-filter: blur(10px);

    > section {
      display: flex;
      margin: 0 auto;
      justify-content: space-around;

      > p {
        color: #fff;
        margin: 24px 0 24px 0;
        font-family: GmarketSansBold, serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 17px;
      }

      > div {
        display: flex;

        > img {
          cursor: pointer;
          //line-height: 60px;
          margin: 16px 7px 16px 0;
          width: 26px;
          height: 26px;
        }
      }
    }
  }
}
