$main01: #10A8CA;
$sub01: #4CAEF5;
$sub02: #1556D4;
$sub03: #12124F;
$sub04: #614AED;
$sub05: #7FC6DA;
$sub06: #C2E7F2;
$sub07: #CAF5FF;
$sub08: #EFF8FB;
$grey01: #2B3639;
$grey02: #7D898B;
$grey03: #A8B1B2;
$grey04: #BDC6C8;
$grey05: #DBE3E4;
$grey06: #F6F8F9;
$white: #FFFFFF;
$warning: #FF6E65;

$maxMobileSize: 1200px;
$minMobileSize: 360px;
$minContentsLayoutWidth: 1200px;
$pageWidth: 940px;

@keyframes upanimation {
  from {
    transform: translateY(70%);
  }
  to {
    transform: translateY(0%);
  }
}
