@media screen and (min-width: $maxMobileSize) {
  .modal-container {
    position: fixed;
    display: grid;
    z-index: 999;
    width: 100%;
    height: 100%;

    .modal-bg {
      position: fixed;
      display: grid;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .7);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      z-index: 11;
    }

    .modal-contents {
      border-radius: 20px;
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }

      z-index: 999;
      width: 410px;
      position: relative;
      max-height: 600px;
      background-color: #fff;
      margin: auto;
      text-align: center;

      .close-btn {
        position: absolute;
        background: none;
        border: none;
        cursor: pointer;
        top: 16px;
        right: 16px;

        img {
          width: 18px;
        }
      }

      .modal-header {
        margin: 30px;

        .modal-title {
          font-weight: 800;
          font-size: 24px;
          line-height: 34px;
          text-align: center;
          color: #2B3639;
          margin: 0 0 20px;
          letter-spacing: -0.025em;

        }
      }
      .refund_modal_terms {
        > div {
          height: 336px;
          overflow-y: auto;
          text-align: left;
          display: block;
          color: #7D898B;
          background-color: rgb(247, 247, 247);
          white-space: normal; //tag response에 사용하면 줄 간격 좋음

          /* 본문_04-midium */
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */

          > p {
            margin: 0;
          }
        }
      }

      .modal-body {
        width: 350px;
        margin: 0 auto 40px auto;
        color: $grey02;
        font-size: 16px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: -0.025em;
        text-align: center;
        white-space: pre-wrap;
        .modal_terms {
          >p{
            color: black;
            font-weight: 800;
          }
          > div {
            background-color: rgb(247, 247, 247);
            height: 410px;
            color: black;
            overflow-y: auto;
            font-size: 12px;
            text-align: left;

            ol {
              padding-left: 10px;
            }

            p {
              margin: 0;
            }
          }
        }
        .captcha_sec {
          display: flex;
          height: 222px;
          padding: 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
          border-radius: 10px;
          background: var(--gray-10, #F6F6F6);

          .captcha_img_box {
            background-color: #FFFFFF;
            padding: 10px 0;
            margin: 0 auto;
            width: 200px;
            height: 86px;

            > img {
              height: 66px;
              flex-shrink: 0;
            }
          }

          .refresh {
            display: flex;
            gap: 6px;
            align-items: center;
            cursor: pointer;
            margin: 0 auto;

            >span {
              height: 24px;
              color: #10A8CA;

              /* noto sans/common/body2-m14 */
              font-family: "Noto Sans KR", sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 171.429% */

              > img {
                width: 18px;
                height: 16px;
              }
            }
          }

          > input {
            display: flex;
            height: 48px;
            padding: 12px 16px;
            justify-content: center;
            align-items: center;
            gap: 6px;
            align-self: stretch;
            border-radius: 12px;
            border: 1px solid var(--common-grayscale-bk-20, #CCC);
            background: var(--common-grayscale-wt-100, #FFF);
          }
        }

        .service-modal {
          text-align: left;
        }
      }

      .modal-captcha-err {
        display: flex;
        width: 100%;
        letter-spacing: 0em;
        background-size: cover;
        justify-content: center;
        color: var(--sub-txt-color_01, #7D898B);
        text-align: center;
        /* [PC]버튼명_03-midium */
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        .warning-icon {
          margin-top: -6px;
          margin-bottom: 20px;
          //padding-bottom: 2px;
          min-width: 18px;
          width: 18px;
          height: 18px;
          text-align: center;
          background-image: url("../assets/img/form/ico_warning.png");
          background-repeat: no-repeat;
          background-size: cover;
        }
        .warning-text {
          margin-top: -8px;
          height: 24px;
        }
      }

      .modal-footer {
        .padding_span {
          padding: 5px;
          width: 5px;

        }
          .confirm{
            margin-bottom: 20px;
          }

        .button-container {

          .print_btn {
            margin: 0 auto;
            width: 170px;
            height: 50px;
            display: block;
            cursor: pointer;
            background: #10A8CA;
            border-radius: 8px;
            border: none;

            > span {
              padding: 0 38px;
              font-weight: 500;
              font-size: 16px;
              line-height: 50px;
              font-family: 'GmarketSansBold', serif;
              color: #FFF;
            }
          }

          margin: 30px;
          display: flex;

          .button-gap {
            width: 12px;
          }
        }

        .under-button-text {

        }
      }
    }
    // 20241104 오토콜 DB이관 공지
    .modal-contents:has(> .modal-body .service-modal) {
      width: 450px;
      .modal-body {
        word-break: keep-all;
        width: 390px;
      }
    }
  }
}

@media screen and (max-width: $maxMobileSize) {
  .modal-container {
    z-index: 999;
    position: fixed;
    display: grid;
    width: 100%;
    margin: 0 auto;
    height: 100%;

    .modal-bg {
      position: fixed;
      display: grid;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .7);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      z-index: 11;
    }

    .modal-contents {
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }

      width: 320px;
      height: auto;
      max-height: 550px;
      overflow: auto;
      background: #FFFFFF;
      box-shadow: 0px 6px 14px rgba(43, 54, 57, 0.3);
      border-radius: 30px;
      margin: auto auto;
      text-align: center;
      z-index: 12;
      position: relative;

      .close-btn {
        position: absolute;
        background: none;
        border: none;
        cursor: pointer;
        top: 16px;
        right: 16px;
      }

      .modal-header {
        margin: 30px 30px 20px 30px;

        .modal-title {
          padding: 0;
          //white-space: nowrap;
          margin: 0 0 16px;
          font-size: 19px;
          font-weight: 700;
          line-height: 33px;
          letter-spacing: -0.025em;
          text-align: center;
        }
      }

      .refund_modal_terms {
        > div {
          height: 336px;
          overflow-y: auto;
          text-align: left;
          display: block;
          color: #7D898B;
          background-color: rgb(247, 247, 247);
          white-space: normal; //tag response에 사용하면 줄 간격 좋음

          /* 본문_04-midium */
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */

          > p {
            margin: 0;
          }
        }
      }

      .modal-body {
        margin: 0 30px 30px 30px;
        color: $grey02;
        font-size: 14px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: -0.025em;
        text-align: center;
        white-space: pre-line;
        .service-modal {
          text-align: left;
        }
        .modal_terms {
          >p{
            color: black;
            font-weight: 800;
          }
          > div {
            background-color: rgb(247, 247, 247);
            height: 300px;
            color: black;
            overflow-y: auto;
            font-size: 10px;
            text-align: left;
            display: block;

            ol {
              padding-left: 10px;
            }

            p {
              margin: 0;
            }
          }
          }
        }
      .captcha_sec {
        display: flex;
        height: 222px;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-radius: 10px;
        background: var(--gray-10, #F6F6F6);

        .captcha_img_box {
          background-color: #FFFFFF;
          padding: 10px 0;
          margin: 0 auto;
          width: 200px;
          height: 86px;

          > img {
            height: 66px;
            flex-shrink: 0;
          }
        }

        .refresh {
          display: flex;
          gap: 6px;
          align-items: center;
          cursor: pointer;
          margin: 0 auto;

          >span {
            height: 24px;
            color: #10A8CA;

            /* noto sans/common/body2-m14 */
            font-family: "Noto Sans KR", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 171.429% */

            > img {
              width: 18px;
              height: 16px;
            }
          }
        }

        > input {
          display: flex;
          height: 48px;
          padding: 12px 16px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          align-self: stretch;
          border-radius: 12px;
          border: 1px solid var(--common-grayscale-bk-20, #CCC);
          background: var(--common-grayscale-wt-100, #FFF);
        }
      }

      .modal-captcha-err {
        display: flex;
        width: 100%;
        letter-spacing: 0em;
        background-size: cover;
        justify-content: center;
        color: var(--sub-txt-color_01, #7D898B);
        text-align: center;
        /* [PC]버튼명_03-midium */
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        .warning-icon {
          margin-top: -6px;
          margin-bottom: 20px;
          //padding-bottom: 2px;
          min-width: 18px;
          width: 18px;
          height: 18px;
          text-align: center;
          background-image: url("../assets/img/form/ico_warning.png");
          background-repeat: no-repeat;
          background-size: cover;
        }
        .warning-text {
          margin-top: -8px;
          margin-bottom: 20px;
          height: 24px;
        }
      }

      .modal-footer {
        margin: 0 30px 30px 30px;

        .padding_span {
          padding: 5px;
          width: 1px;

        }

        .button-container {
          display: flex;

          .print_btn {
            margin: 0 auto;
            width: 125px;
            height: 50px;
            display: block;
            cursor: pointer;
            background: #10A8CA;
            border-radius: 8px;
            border: none;

            > span {
              font-weight: 500;
              font-size: 16px;
              line-height: 50px;
              font-family: 'GmarketSansBold', serif;
              color: #FFF;
            }
          }

          .button-gap {
            width: 12px;
          }
        }

        .under-button-text {

        }
      }
    }
  }
}